import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomModal } from 'common/components/CustomModal';
import { ANSWER_AREAS } from '../../../constants/print-constants';

const PrintTestOptions = ({ options, handlePrint, maxQuestions, handleCloseModal, setOptions }) => {
  const intl = useIntl();

  /**
   * Handles changes to the answer area options.
   * @param {string} answerArea - The selected answer area option.
   */
  const handleAnswerAreaOptionChange = answerArea => {
    const newOptions = { ...options };

    if (answerArea === ANSWER_AREAS.BETWEEN_QUESTIONS) {
      if (Object.keys(newOptions.lineBreaks).length === 0) {
        newOptions.lineBreakAt = 1;
      }
    } else {
      newOptions.lineBreakAt = 1;
      newOptions.lineBreaks = {};
    }

    setOptions({ ...newOptions, answerArea });
  };

  /**
   * Handles adding or removing a line break at the specified index.
   * @param {boolean} isAdd - Whether to add (true) or remove (false) a line break.
   */
  const handleLineBreak = isAdd => {
    setOptions(options => {
      const { lineBreaks, lineBreakAt } = options;
      const newLineBreaks = {
        ...lineBreaks,
        [lineBreakAt]: isAdd ? (lineBreaks[lineBreakAt] || 0) + 1 : Math.max((lineBreaks[lineBreakAt] || 0) - 1, 0),
      };
      return { ...options, lineBreaks: newLineBreaks };
    });
  };

  /**
   * Handles changes to the question number input field.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
   */
  const handleQuestionNumberChange = event => {
    const { value, validationMessage } = event.target;
    // If the input field has no validation errors, update the options with the new line break at value
    if (!validationMessage) {
      setOptions(options => ({ ...options, lineBreakAt: value }));
    }
  };

  return (
    <div className="print-options">
      <CustomModal.Header className="print-options-header">
        <h3>
          <FormattedMessage id="printoption" />
        </h3>
      </CustomModal.Header>
      <CustomModal.Body className="print-options-body flex-grow-1">
        <Row>
          <Col md={6}>
            <div>
              <FormattedMessage id="studentanswerarea" />
            </div>
          </Col>
          <Col md={6}>
            <Form.Check
              type="radio"
              id="none"
              label={intl.formatMessage({ id: 'radionone' })}
              checked={options.answerArea === ANSWER_AREAS.NONE}
              onChange={() => {
                handleAnswerAreaOptionChange(ANSWER_AREAS.NONE);
              }}
            />
            <Form.Check
              type="radio"
              id="between-questions"
              label={intl.formatMessage({ id: 'betweenquestions' })}
              checked={options.answerArea === ANSWER_AREAS.BETWEEN_QUESTIONS}
              onChange={() => {
                handleAnswerAreaOptionChange(ANSWER_AREAS.BETWEEN_QUESTIONS);
              }}
            />

            {options.answerArea === ANSWER_AREAS.BETWEEN_QUESTIONS && (
              <div>
                <div className="question-no d-flex align-items-center mb-2">
                  <span>
                    <FormattedMessage id="enterquestionno" />
                  </span>
                  <Form.Control
                    style={{ width: '63px', padding: '5px 5px' }}
                    className="ms-2"
                    type="number"
                    onChange={handleQuestionNumberChange}
                    value={options.lineBreakAt}
                    min={1}
                    max={maxQuestions}
                  />
                </div>
                <div className="add-remove-buttons d-flex gap-1 flex-wrap mb-1">
                  <Button size="sm" onClick={() => handleLineBreak(true)} disabled={!options.lineBreakAt}>
                    <FormattedMessage id="addline" />
                  </Button>
                  <Button size="sm" onClick={() => handleLineBreak(false)} disabled={!options.lineBreakAt}>
                    <FormattedMessage id="removeline" />
                  </Button>
                </div>
              </div>
            )}
            <Form.Check
              type="radio"
              id="left-side-of-the-page"
              label={intl.formatMessage({ id: 'leftsidepage' })}
              checked={options.answerArea === ANSWER_AREAS.LEFT_SIDE_OF_THE_PAGE}
              onChange={() => {
                handleAnswerAreaOptionChange(ANSWER_AREAS.LEFT_SIDE_OF_THE_PAGE);
              }}
            />
            <Form.Check
              type="radio"
              id="blank-last-page"
              label={intl.formatMessage({ id: 'blanklastpage' })}
              checked={options.answerArea === ANSWER_AREAS.BLANK_LAST_PAGE}
              onChange={() => {
                handleAnswerAreaOptionChange(ANSWER_AREAS.BLANK_LAST_PAGE);
              }}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}>
            <div>
              <FormattedMessage id="labelsPrintMessage" />
            </div>
          </Col>
          <Col md={6}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                checked={options.addStudentName}
                onChange={e => setOptions(options => ({ ...options, addStudentName: e.target.checked }))}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <FormattedMessage id="studentnamelabel" />
              </label>
            </div>
          </Col>
          <div className="mt-4 d-flex align-items-start">
            <i className="bi bi-info-circle-fill large-icon me-1" id="info-icon"></i>
            <span className="print-options-info-text">
              <FormattedMessage id="exportbuttoninfo" />
            </span>
          </div>
        </Row>
      </CustomModal.Body>
      <CustomModal.Footer className="button-footer">
        <Button variant="secondary" onClick={handleCloseModal}>
          <FormattedMessage id="message.cancel" />
        </Button>
        <Button variant="primary" onClick={handlePrint}>
          <FormattedMessage id="testtabs.print" />
        </Button>
      </CustomModal.Footer>
    </div>
  );
};

export default PrintTestOptions;
