import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { CustomModal } from 'common/components/CustomModal';
import { toastify } from 'common/components/Toastify';
import { useLoader } from 'common/providers/LoaderProvider';
import { addBook, updateBook } from 'workspace/api/books.service';
import disciplines from 'workspace/data/disciplines';
import publishers from 'workspace/data/publishers';
import { useAuth } from 'workspace/providers/auth/AuthContext';
import { getValidationFunction } from 'common/utils/validations';

const MAX_AUTHORS_COUNT = process.env.REACT_APP_MAX_ALLOWED_WS_AUTHORS_COUNT;

const BOOK_FORM_VALIDATIONS = {
  title: {
    required: 'ws.error.required.bookTitle',
  },
  publisher: {
    required: 'ws.error.required.publisher',
  },
  discipline: {
    required: 'ws.error.required.discipline',
  },
  firstName: {
    required: 'ws.error.required.firstName',
  },
  lastName: {
    required: 'ws.error.required.lastName',
  },
};

const validate = getValidationFunction(BOOK_FORM_VALIDATIONS);

/**
 * Gets the validation errors for the given form data.
 *
 * @param {Object} formData - The form data to validate.
 * @param {boolean} isEditUser  - Whether the user is being edited or created.
 * @returns {Object} - An object with error messages for each invalid field.
 */
const getValidationErrors = (formData, isEditUser) => {
  const errors = {};

  Object.entries(formData).forEach(([key, value]) => {
    if (key === 'authors') {
      const authors = formData.authors
        .map(author => {
          const authorErrors = Object.entries(author).reduce((acc, [k, v]) => {
            const error = validate(k, v);
            if (error) {
              acc[k] = error;
            }
            return acc;
          }, {});

          return Object.keys(authorErrors).length ? { id: author.id, ...authorErrors } : null;
        })
        .filter(author => author);

      if (authors.length) {
        errors.authors = authors;
      }
    } else {
      const error = validate(key, value);
      if (error) {
        errors[key] = error;
      }
    }
  });

  return errors;
};

function generateAuthorsInfo(authors) {
  return authors.map(author => {
    const nameParts = author.trim().split(' ');

    const firstName = nameParts[0];
    const middleName = nameParts.length === 3 ? nameParts[1] : '';
    const lastName = nameParts.length === 2 ? nameParts[1] : nameParts[2];

    return {
      id: uuidv4(),
      firstName,
      middleName,
      lastName,
    };
  });
}

const CreateOrEditBookModal = ({ activeBook, handleClose, reload }) => {
  const intl = useIntl();
  // Initialize state with activeBook's values or defaults
  const [submitted, setSubmitted] = useState(false);
  const { userData } = useAuth();
  const { showLoader, hideLoader } = useLoader();
  const [formData, setFormData] = useState({
    title: activeBook?.title || '',
    bookDescription: activeBook?.bookDescription || '',
    publisher: activeBook?.publisher || '',
    discipline: activeBook?.discipline || '',
    authors:
      activeBook?.authors?.length > 0
        ? generateAuthorsInfo(activeBook.authors)
        : [{ id: uuidv4(), firstName: '', middleName: '', lastName: '' }],
    description: activeBook?.description || '',
  });

  const [errors, setErrors] = useState({
    title: false,
    publisher: false,
    discipline: false,
    authors: [],
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const validation = validate(name, value);
    setErrors(prev => ({ ...prev, [name]: validation || '' }));
  };

  const handleAuthorChange = (id, field, value) => {
    const updatedAuthors = formData.authors.map(author => (author.id === id ? { ...author, [field]: value } : author));
    setFormData({ ...formData, authors: updatedAuthors });

    const updatedErrors = errors.authors.map(author =>
      author.id === id ? { ...author, [field]: validate(field, value) } : author
    );
    setErrors({ ...errors, authors: updatedErrors });
  };

  const handleAddAuthor = () => {
    if (formData.authors.length >= MAX_AUTHORS_COUNT) {
      const msg = intl.formatMessage({ id: 'warning.cannotAddMoreAuthors' }, { count: MAX_AUTHORS_COUNT });
      toastify.showWarningToast(msg);
      return;
    }

    setFormData(prevState => ({
      ...prevState,
      authors: [...prevState.authors, { id: uuidv4(), firstName: '', middleName: '', lastName: '' }],
    }));
  };

  const handleRemoveAuthor = id => {
    const updatedAuthors = formData.authors.filter(author => author.id !== id);
    setFormData({ ...formData, authors: updatedAuthors });
  };

  const handleAddBook = async () => {
    setSubmitted(true);

    // Validate the form data
    const validationErrors = getValidationErrors(formData);

    // If there are validation errors, display them and exit
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const bookData = {
      title: formData.title,
      publisher: formData.publisher,
      discipline: formData.discipline,
      authors: formData.authors.map(author => `${author.firstName} ${author.middleName} ${author.lastName}`.trim()),
      createdBy: userData.userId,
      created: new Date().toISOString(),
      isbn: '',
      isbn10: '',
      isbn13: '',
      editionNumber: 1,
      testBindings: [],
      description: formData.description,
    };

    if (activeBook) {
      try {
        showLoader();
        const payload = {
          title: formData.title,
          description: formData.description,
          publisher: formData.publisher,
          discipline: formData.discipline,
          authors: formData.authors.map(author => `${author.firstName} ${author.middleName} ${author.lastName}`.trim()),
        };
        await updateBook(activeBook.guid, payload);
        toastify.showSuccessToast('Book updated successfully');
        handleClose();
        reload();
      } catch (error) {
        toastify.showErrorToast(error?.message);
      } finally {
        hideLoader();
      }
    } else {
      try {
        showLoader();
        await addBook(bookData);
        toastify.showSuccessToast('Book added successfully');
        handleClose();
        reload();
      } catch (error) {
        if (error.status === 409) {
          toastify.showWarningToast(error.message);
        } else {
          toastify.showErrorToast(error?.message);
        }
      } finally {
        hideLoader();
      }
    }
  };

  return (
    <CustomModal show size="lg" className="create-book-modal">
      <CustomModal.Header onClose={handleClose}>
        <FormattedMessage
          id={activeBook ? 'ws.editBook' : 'ws.bookCreation'}
          defaultMessage={activeBook ? 'Edit Book' : 'Create Book'}
        />
      </CustomModal.Header>
      <CustomModal.Body className="overflow-y-auto">
        <Form>
          <Form.Group controlId="formBookTitle" className="mb-2">
            <Form.Label className="ws-form-label">
              <FormattedMessage id="ws.bookTitle" />
              <span className="required-asterisk">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Book Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              maxLength={150}
              className="form-control-book"
            />
            {submitted && errors.title && (
              <div className="form-error-message">
                <FormattedMessage id={errors.title} />
              </div>
            )}
          </Form.Group>
          <Form.Group controlId="formBookDescription" className="mb-2">
            <Form.Label className="ws-form-label">
              <FormattedMessage id="ws.bookDescription" />
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              maxLength={250}
              className="form-control-book"
              rows={4} // Optional: You can define the number of rows (height of the textarea)
            />
          </Form.Group>
          <Form.Group controlId="formPublisher" className="mb-2">
            <Form.Label className="ws-form-label">
              <FormattedMessage id="ws.publisher" />
              <span className="required-asterisk">*</span>
            </Form.Label>
            <Form.Select
              name="publisher"
              value={formData.publisher}
              onChange={handleChange}
              className="form-control-book"
            >
              <option value="">Publisher</option> {/* Placeholder for default */}
              {publishers.map((publisher, index) => (
                <option key={index} value={publisher}>
                  {publisher}
                </option>
              ))}
            </Form.Select>
            {submitted && errors.publisher && (
              <div className="form-error-message">
                <FormattedMessage id={errors.publisher} />
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="formDiscipline" className="mb-2">
            <Form.Label className="ws-form-label">
              <FormattedMessage id="ws.discipline" />
              <span className="required-asterisk">*</span>
            </Form.Label>
            <Form.Select
              name="discipline"
              value={formData.discipline}
              onChange={handleChange}
              className="form-control-book"
            >
              <option value=""> Discipline</option> {/* Placeholder for default */}
              {disciplines
                .sort((a, b) => a.localeCompare(b))
                .map((discipline, index) => (
                  <option key={index} value={discipline}>
                    {discipline}
                  </option>
                ))}
            </Form.Select>
            {submitted && errors.discipline && (
              <div className="form-error-message">
                <FormattedMessage id={errors.discipline} />
              </div>
            )}
          </Form.Group>

          {/* Authors Section */}
          <div className="d-flex align-items-center">
            <Form.Label className="ws-form-label">
              <FormattedMessage id="ws.author" />
              <span className="required-asterisk">*</span>
            </Form.Label>
            <Button variant="link" onClick={handleAddAuthor} className="flex" title="Add Author">
              <i className="fas fa-plus-circle" />
            </Button>
          </div>

          {/* Author Fields */}
          {formData.authors.map((author, index) => {
            const authorError = errors.authors?.find(item => item.id === author.id);
            return (
              <div key={author.id} className="author-item row">
                <div className="col-9">
                  <div className="author-fields">
                    <Form.Group controlId={`formAuthorFirstName${author.id}`}>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        value={author.firstName}
                        onChange={e => handleAuthorChange(author.id, 'firstName', e.target.value)}
                        maxLength={25}
                        className="form-control-book"
                      />
                      {submitted && authorError?.firstName && (
                        <div className="form-error-message">
                          <FormattedMessage id={authorError?.firstName} />
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group controlId={`formAuthorMiddleName${author.id}`}>
                      <Form.Control
                        type="text"
                        placeholder="Middle Name"
                        value={author.middleName}
                        onChange={e => handleAuthorChange(author.id, 'middleName', e.target.value)}
                        maxLength={1}
                        className="form-control-book"
                      />
                    </Form.Group>

                    <Form.Group controlId={`formAuthorLastName${author.id}`}>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        value={author.lastName}
                        onChange={e => handleAuthorChange(author.id, 'lastName', e.target.value)}
                        maxLength={25}
                        className="form-control-book"
                      />
                      {submitted && authorError?.lastName && (
                        <div className="form-error-message">
                          <FormattedMessage id={authorError?.lastName} />
                        </div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className="col-3">
                  {formData.authors.length > 1 && index !== 0 && (
                    <Button
                      title="Remove"
                      style={{ color: 'black', backgroundColor: 'white' }}
                      onClick={() => handleRemoveAuthor(author.id)}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
        </Form>
      </CustomModal.Body>
      <CustomModal.Footer>
        <Button variant="secondary" size="sm" onClick={handleClose}>
          <FormattedMessage id="message.cancel" />
        </Button>
        <Button variant="primary" size="sm" onClick={handleAddBook}>
          <FormattedMessage id="message.save" />
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
};

export default CreateOrEditBookModal;
