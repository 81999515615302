import React, { useState, useEffect, useRef } from 'react';
import { Tree } from '@minoru/react-dnd-treeview';
import QuestionMapper from './questions/QuestionMapper';
import { useAppContext } from '../context/AppContext';
import { isAllQuestionsAreInViewMode } from 'common/utils/questions-utils';
import './TreeViewTestCreate.css';

const TreeViewTestCreate = ({ data }) => {
  const { testTabsDispatch } = useAppContext();
  const [treeData, setTreeData] = useState([]);
  const [canDrag, setCanDrag] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const dragSourceIdRef = useRef(null);

  useEffect(() => {
    setTreeData(renderTreeNodes(data));
    setCanDrag(isAllQuestionsAreInViewMode(data));
  }, [data]);

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleMouseDown = () => {
    canDrag && setIsDragging(true);
  };

  const handleMouseUp = () => {
    canDrag && setIsDragging(false);
  };

  /**
   * Renders a tree of nodes as a list of objects with `id`, `content`, `children`, and `parent` properties.
   * @param {Object[]} nodes - The array of nodes to render.
   * @returns {Object[]} The rendered tree nodes.
   */
  const renderTreeNodes = nodes => {
    return nodes.map((node, index) => ({
      id: (node.itemId || node.guid) + ':' + index,
      content: <QuestionMapper questionNode={node} index={index} showActionButtons />,
      children: node.children && node.children.length > 0 ? renderTreeNodes(node.children) : undefined,
      parent: 0,
    }));
  };

  /**
   * Handles the drop event when a node is dragged and dropped onto another node in the tree.
   * @param {Object[]} nodes - The array of nodes in the tree.
   * @param {Object} dropTargetId - The id of the node being dropped onto.
   */
  const handleDrop = (nodes, { dropTargetId }) => {
    testTabsDispatch({
      type: 'REARRANGE_QUESTIONS',
      payload: {
        dragSourceId: dragSourceIdRef.current,
        dropTargetId: dropTargetId.split(':')[0],
      },
    });
    dragSourceIdRef.current = null;
  };

  /**
   * Handles the drag start event for a drag source.
   * @param {Object} dragSource - The drag source object that was dragged.
   */
  const handleDragStart = dragSource => {
    dragSourceIdRef.current = dragSource.id.split(':')[0];
    setIsDragging(true);
  };

  return (
    <div
      className={`tree-container ${canDrag ? 'pointer' : ''} ${isDragging ? 'grabbing' : ''}`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {treeData && (
        <Tree
          tree={treeData}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          rootId={0}
          render={node => <div className="p-2">{node.content}</div>}
          onDrop={handleDrop}
          canDrop={(_currentTree, { dropTarget }) => {
            return !!dropTarget && !!dragSourceIdRef.current;
          }}
          canDrag={() => canDrag}
          sort={false}
          classes={{
            root: 'treeRoot',
            draggingSource: 'draggingSource',
            dropTarget: 'dropTarget',
          }}
        />
      )}
    </div>
  );
};

export default TreeViewTestCreate;
