import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useDebounce } from '../../hooks/useDebounce';
import './SearchBox.css';

function SearchBox({ placeholder, searchText, onSearch, onClear }) {
  const [searchTerm, setSearchTerm] = useState(searchText || '');
  const debouncedSearch = useDebounce(searchTerm);

  useEffect(() => {
    onSearch(debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    if (searchText !== undefined && searchTerm !== null) setSearchTerm(searchText);
  }, [searchText]);

  const handleClear = () => {
    setSearchTerm('');
    onClear && onClear();
  };

  return (
    <div className="search-box input-group rounded">
      <Form.Control
        type="text"
        size="sm"
        width="100%"
        className="form-control rounded"
        placeholder={placeholder || 'Search...'}
        aria-label="Search"
        aria-describedby="search-addon-noborder"
        value={searchTerm}
        onChange={event => setSearchTerm(event.target.value)}
      />
      <div className="input-group-append">
        <span className="input-group-icon border-0">
          {searchTerm ? (
            <i
              className="fas fa-times pointer"
              onClick={handleClear}
              title="Clear"
              tabIndex="0"
              aria-label="clear search"
            ></i>
          ) : (
            <i className="fas fa-search" title="Search"></i>
          )}
        </span>
      </div>
    </div>
  );
}

export default SearchBox;
