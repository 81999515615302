import { useState } from 'react';
import { Button, Form, Alert, Spinner } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomModal } from 'common/components/CustomModal';
import { toastify } from 'common/components/Toastify';
import { publish } from 'common/utils/events';
import { useManageQuestionsContext } from 'workspace/providers/manage-questions/ManageQuestionsProvider';
import { importQuestions } from 'workspace/api/questions.service';

const ImportQuestion = () => {
  const intl = useIntl(); // Initialize intl for formatting messages
  const { selectedBook, selectedFolder, treeData } = useManageQuestionsContext();
  const [showImportModal, setShowImportModal] = useState(false);
  const [file, setFile] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleShowModal = () => {
    setShowImportModal(true);
    setAlertMessage(''); // Clear any previous alert messages when opening the modal
  };

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];

    // Clear alert messages initially
    setAlertMessage('');

    if (selectedFile) {
      // Check if the file is a ZIP file
      if (selectedFile.type === 'application/zip' || selectedFile.name.endsWith('.zip')) {
        setFile(selectedFile);
      } else {
        // Set validation message for invalid file type
        setAlertMessage('Please select a ZIP file only.');
        setFile(null); // Reset file if invalid
      }
    } else {
      setAlertMessage('No file selected. Please select a file to upload.');
    }
  };

  const handleImport = async () => {
    if (file) {
      try {
        setLoading(true); // Set loading state to true before making API call

        const bookId = selectedBook.guid;
        const folderId = selectedFolder || treeData.guid;

        await importQuestions(bookId, folderId, file);
        toastify.showSuccessToast(intl.formatMessage({ id: 'success.questions.import' }));
        setShowImportModal(false);
        setFile(null);
        // publish event to reload folders
        publish('reload_folders');
      } catch (error) {
        if (error.status === 409) {
          toastify.showWarningToast(error.message);
        } else {
          toastify.showErrorToast(intl.formatMessage({ id: 'error.documentExport' }));
        }
      } finally {
        setLoading(false); // Set loading state to false after API call
      }
    }
  };

  return (
    <>
      <Button className="color-black" variant="outline-light" onClick={handleShowModal}>
        <i className="fa-solid fa-arrow-down"></i>&ensp;
        <FormattedMessage id="message.import" />
      </Button>

      {/* Import Question Modal */}
      <CustomModal show={showImportModal} size="sm" centered={false}>
        <CustomModal.Header onClose={() => setShowImportModal(false)}>
          <FormattedMessage id="importQuestions.title" defaultMessage="Import Questions" />
        </CustomModal.Header>
        <CustomModal.Body>
          {alertMessage && <Alert variant="danger">{alertMessage}</Alert>}
          <Form.Group controlId="formFileUpload">
            <Form.Label className="font-size-16px">
              <FormattedMessage
                id="fileUploadInstructions"
                defaultMessage="Select a QTI 2.1 question package (ZIP file) and click OK"
              />
            </Form.Label>

            <Form>
              <Form.Control type="file" onChange={handleFileChange} accept=".zip" />
            </Form>
          </Form.Group>
        </CustomModal.Body>
        <CustomModal.Footer>
          <Button variant="secondary" size="sm" onClick={() => setShowImportModal(false)}>
            <FormattedMessage id="message.cancel" defaultMessage="Cancel" />
          </Button>
          <Button variant="primary" size="sm" onClick={handleImport} disabled={!file}>
            <FormattedMessage id="message.ok" defaultMessage="OK" />
          </Button>
        </CustomModal.Footer>

        {loading && (
          <div className="loader-inside-modal-overlay">
            <Spinner />
          </div>
        )}
      </CustomModal>
    </>
  );
};

export default ImportQuestion;
