import React, { createContext, useMemo, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useLoader } from 'common/providers/LoaderProvider';
import { AUTH_ACTIONS, authReducer } from './authReducer';

/**
 * Authentication context
 */
const AuthContext = createContext();

const storedToken = localStorage.getItem('wstoken');

// Initial state for the authentication context
const initialData = {
  token: storedToken,
  userData: storedToken ? jwtDecode(storedToken) : null,
};

/**
 * AuthProvider component
 *
 * @param {ReactNode} children - The children components to render
 */
const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { hideLoader } = useLoader();

  const [state, dispatch] = useReducer(authReducer, initialData);

  /**
   * Handles a successful login by decoding the provided token, setting the token and user data, and navigating to the default route.
   *
   * @param {string} data - data with The JSON Web Token (JWT) returned from the login API
   *
   */
  const loginSuccessful = data => {
    dispatch({ type: AUTH_ACTIONS.LOGIN_SUCCESS, payload: data.token });
    setTimeout(() => navigate(''), 100);
  };

  /**
   * Logs out the user by clearing token and user data, and navigating to the default route.
   *
   */
  const logout = () => {
    dispatch({ type: AUTH_ACTIONS.LOGOUT });
    hideLoader();
    navigate('');
  };

  const openWorkspace = workspaceData => {
    dispatch({
      type: AUTH_ACTIONS.SET_ACTIVE_WORKSPACE,
      payload: {
        workspaceId: workspaceData.id,
        workspaceName: workspaceData.name,
      },
    });

    navigate('/ws/manageusers');
  };
  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      ...state,
      loginSuccessful,
      logout,
      openWorkspace,
    }),
    [state]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

/**
 * Hook to access the authentication context
 *
 * @returns {object} - The authentication context
 *
 * @throws {Error} - If used outside of an AuthProvider
 *
 */
export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
