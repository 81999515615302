import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import AddWorkspaceModal from './AddWorkspaceModal';
import SearchBox from 'common/components/SearchBox/SearchBox';
import { getAllWorksapce } from 'workspace/api/workspace.service';
import { useLoader } from 'common/providers/LoaderProvider';
import { toastify } from 'common/components/Toastify';
import { formatDate } from 'common/utils';
import './manage-workspace.css';
import { useAuth } from 'workspace/providers/auth/AuthContext';

const ManageWorkspace = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { openWorkspace } = useAuth();
  const { showLoader, hideLoader } = useLoader();
  const [searchTerm, setSearchTerm] = useState('');
  const [showCreateWorkspace, setShowCreateWorkspace] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [editWorkspace, setEditWorkspace] = useState();

  useEffect(() => {
    fetchWorkspaceData();
  }, []);

  const fetchWorkspaceData = async () => {
    try {
      showLoader();
      const workspaceDetails = await getAllWorksapce();
      setWorkspaceData(workspaceDetails);
    } catch (err) {
      if (err.status === 404) {
        setWorkspaceData([]);
        return;
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'ws.unabletofetchworkspace' }));
      }
    } finally {
      hideLoader();
    }
  };

  const handleSearchChange = value => {
    setSearchTerm(value);
  };

  const handleCreateOrEditWorkspace = workspace => {
    setShowCreateWorkspace(true);
    if (workspace) {
      setEditWorkspace(workspace);
    }
  };

  const handleClose = () => {
    setShowCreateWorkspace(false);
  };

  const handleOpenWorkspace = workspaceData => {
    openWorkspace(workspaceData);
  };

  return (
    <div className="manage-workspace-container">
      <h4>
        <FormattedMessage id="ws.management" />
      </h4>
      <Row>
        <Col xs={12} className="workspace-list-actions-container">
          <div className="workspace-list-actions-left">
            <SearchBox searchText={searchTerm} placeholder="Search Workspace" onSearch={handleSearchChange} />
          </div>
          <div className="workspace-list-actions-right">
            <Button size="sm" onClick={() => handleCreateOrEditWorkspace()}>
              <FormattedMessage id="ws.addworkspace" />
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="ws.workspaceName" />
                </th>
                <th>
                  <FormattedMessage id="ws.workspaceDescription" />
                </th>
                <th style={{ width: '120px' }}>
                  <FormattedMessage id="ws.createddate" />
                </th>
                <th style={{ width: '120px' }}>
                  <FormattedMessage id="ws.modifiedDate" />
                </th>
                <th style={{ width: '120px' }}>
                  <FormattedMessage id="ws.createdBy" />
                </th>
                <th style={{ width: '190px' }}>
                  <FormattedMessage id="ws.actions" />
                </th>
              </tr>
            </thead>
            <tbody>
              {workspaceData.map((workspace, index) => (
                <tr key={index}>
                  <td>{workspace.name}</td>
                  <td>{workspace.description}</td>
                  <td>{formatDate(workspace.createdDate) || <FormattedMessage id="ws.book.na" />}</td>
                  <td>{formatDate(workspace.createdDate) || <FormattedMessage id="ws.book.na" />}</td>
                  <td>{workspace.createdBy}</td>
                  <td>
                    <Button variant="primary" size="sm" className="mr-2" onClick={() => handleOpenWorkspace(workspace)}>
                      <FormattedMessage id="ws.open" />
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      className="mr-2"
                      onClick={() => handleCreateOrEditWorkspace(workspace)}
                    >
                      <FormattedMessage id="message.edit" />
                    </Button>
                    <Button variant="secondary" size="sm">
                      <FormattedMessage id="message.delete" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      {showCreateWorkspace && (
        <AddWorkspaceModal handleClose={handleClose} reload={fetchWorkspaceData} editWorkspace={editWorkspace} />
      )}
    </div>
  );
};

export default ManageWorkspace;
