import { createContext, useContext } from 'react';

/**
 * Creates a context for the Editor Root component.
 */
export const EditorRootContext = createContext(undefined);

/**
 * Hook to access the Editor Root context.
 *
 * @throws {Error} If used outside of an EditorProvider.
 * @returns {object} The Editor context.
 */
// eslint-disable-next-line react-refresh/only-export-components
export const useEditorRootContext = () => {
  const context = useContext(EditorRootContext);

  if (context === undefined) {
    throw new Error('useEditorContext must be used within a EditorProvider');
  }

  return context;
};

export const EditorRootProvider = ({ children, imageUploadAPIService }) => {
  return <EditorRootContext.Provider value={{ imageUploadAPIService }}>{children}</EditorRootContext.Provider>;
};
