import React, { useEffect, useState } from 'react';
import { Button, Table, Form, Spinner } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomModal } from 'common/components/CustomModal';
import SearchBox from 'common/components/SearchBox/SearchBox';
import { toastify } from 'common/components/Toastify';
import { getBooks, getAllCards, addBookList } from 'workspace/api/books.service';

const BookListModal = ({ reload, handleClose }) => {
  const intl = useIntl();
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    loadData();
  }, []);

  // Update filtered books when search changes
  useEffect(() => {
    setFilteredBooks(books.filter(book => book.title.toLowerCase().includes(searchTerm.toLowerCase())));
  }, [searchTerm]);

  const handleSave = async () => {
    try {
      setLoading(true);
      await addBookList(selectedBooks);
      toastify.showSuccessToast(intl.formatMessage({ id: 'ws.selectedbooksas.cards' }));
      handleClose();
      reload();
    } catch (error) {
      toastify.showErrorToast(error?.message);
    } finally {
      setLoading(false);
    }
    handleClose();
  };

  const handleCheckboxChange = guid => {
    setSelectedBooks(prev => {
      const isSelected = prev.includes(guid);
      return isSelected ? prev.filter(item => item !== guid) : [...prev, guid];
    });
  };

  const loadData = async () => {
    setLoading(true);

    try {
      const [booksResponse, cardsResponse] = await Promise.allSettled([getBooks(false), getAllCards()]);

      handleBooksResponse(booksResponse);
      handleCardsResponse(cardsResponse);
    } catch (error) {
      console.error('An unexpected error occurred:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleBooksResponse = response => {
    if (response.status === 'fulfilled') {
      setBooks(response.value);
      setFilteredBooks(response.value);
    } else {
      if (response.reason.status === 404) {
        setBooks([]);
        setFilteredBooks([]);
        return;
      }

      toastify.showErrorToast(intl.formatMessage({ id: 'ws.unabletofetchbook' }));
    }
  };

  const handleCardsResponse = response => {
    if (response.status === 'fulfilled') {
      setSelectedBooks(response.value.bookId);
    } else {
      if (response.reason.status !== 404) {
        toastify.showErrorToast(response.reason?.message);
      }
    }
  };

  const handleSearchChange = value => {
    setSearchTerm(value);
  };

  const checkAllCheckbox = e => {
    const isChecked = e.target.checked;
    setSelectedBooks(isChecked ? filteredBooks.map(book => book.guid) : []);
  };

  const isAllSelected = filteredBooks.length > 0 && filteredBooks.every(book => selectedBooks.includes(book.guid));

  return (
    <CustomModal size="lg" show centered={false} className="book-list-modal">
      <CustomModal.Header onClose={handleClose}>
        <FormattedMessage id="ws.booklist" defaultMessage="Book List" />
      </CustomModal.Header>
      <CustomModal.Body>
        <div className="mb-2">
          <SearchBox searchText={searchTerm} placeholder="Search Books" onSearch={handleSearchChange} />
        </div>
        {filteredBooks.length === 0 ? (
          <div className="no-books-found">
            <FormattedMessage id="no_matching_books_message" defaultMessage="No matching books found" />
          </div>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="ws.bookTitle" defaultMessage="Title" />
                </th>
                <th>
                  <FormattedMessage id="ws.createdby" defaultMessage="Created By" />
                </th>
                <th style={{ width: '120px' }}>
                  <FormattedMessage id="ws.showincard" defaultMessage="Show card" />
                  <Form.Check
                    type="checkbox"
                    className="select-all-checkbox"
                    checked={isAllSelected}
                    onChange={checkAllCheckbox}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredBooks.map(book => (
                <tr key={book.guid}>
                  <td className="mw-200px text-truncate" title={book.title}>
                    {book.title}
                  </td>
                  <td className="mw-100px text-truncate" title={book.creatorName}>
                    {book.creatorName}
                  </td>
                  <td className="text-center">
                    <Form.Check
                      type="checkbox"
                      checked={selectedBooks.includes(book.guid)}
                      onChange={() => handleCheckboxChange(book.guid)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </CustomModal.Body>
      <CustomModal.Footer>
        <Button variant="secondary" size="sm" onClick={handleClose}>
          <FormattedMessage id="ws.cancel" defaultMessage="Cancel" />
        </Button>
        <Button variant="primary" size="sm" onClick={handleSave}>
          <FormattedMessage id="ws.save" defaultMessage="Save" />
        </Button>
      </CustomModal.Footer>
      {loading && (
        <div className="loader-inside-modal-overlay">
          <Spinner />
        </div>
      )}
    </CustomModal>
  );
};

export default BookListModal;
