import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { CustomModal } from 'common/components/CustomModal';
import { updateQuestion } from 'workspace/api/questions.service';
import { toastify } from 'common/components/Toastify';
import { buildQuestionEnvelopForEdit } from 'common/utils/questions-utils';
import { useManageQuestionsContext } from 'workspace/providers/manage-questions/ManageQuestionsProvider';

import { QUESTION_COMPONENTS } from 'common/components/questions/question-mapping';
import { DEFAULT_EXTENDED_METADATA } from 'common/constants/question-constants';

const userMetadata = Object.keys(DEFAULT_EXTENDED_METADATA);
const QuestionPlaceholder = props => {
  return React.createElement(QUESTION_COMPONENTS[props.questionNode.quizType], {
    ...props,
    userMetadata,
  });
};

const EditSavedQuestionModal = ({ question, onClose }) => {
  const intl = useIntl();
  const { selectedBook, updateQuestionInsideTree } = useManageQuestionsContext();
  const [questionToBeEdited, setQuestionToBeEdited] = useState(question.data);
  const parentFolderId = question.parent;

  const handleSave = async () => {
    try {
      const payload = buildQuestionEnvelopForEdit(questionToBeEdited, {});

      // calls the api to update the question
      await updateQuestion(selectedBook.guid, question.parent, payload);

      // update the question in the tree
      updateQuestionInsideTree(questionToBeEdited, parentFolderId);

      toastify.showSuccessToast(intl.formatMessage({ id: 'success.question.modified' }));
    } catch (error) {
      toastify.showErrorToast(error?.message);
    }
    onClose();
  };

  const handlePreview = data => {
    setQuestionToBeEdited(data.question);
  };

  const handleEdit = () => {
    setQuestionToBeEdited(question => ({ ...question, qtiModel: { ...question.qtiModel, isInEditView: true } }));
  };

  return (
    <CustomModal show={true} size="lg" className="edit-saved-question-modal">
      <CustomModal.Header onClose={onClose}>Edit Question</CustomModal.Header>
      <CustomModal.Body>
        <QuestionPlaceholder
          key={questionToBeEdited.guid}
          questionNode={questionToBeEdited}
          showActionButtons
          isSingleEdit
          onSubmit={handlePreview}
          onEdit={handleEdit}
        />
      </CustomModal.Body>
      <CustomModal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="button"
          className="btn btn-primary"
          onClick={handleSave}
          disabled={questionToBeEdited.qtiModel.isInEditView}
        >
          Save
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
};

export default EditSavedQuestionModal;
