import { Navigate } from 'react-router-dom';
import { useAuth } from 'workspace/providers/auth/AuthContext';
import { ROLES } from 'workspace/constants';

const RoleBasedRoutes = ({ children, allowedRoles }) => {
  const { userData } = useAuth();

  if (userData && !allowedRoles?.includes(userData.role)) {
    switch (userData.role) {
      case ROLES.SUPER_ADMIN:
        return <Navigate to="/ws/manageworkspace" />;
      case ROLES.ADMIN:
        return <Navigate to="/ws/manageusers" />;
      case ROLES.AUTHOR:
        return <Navigate to="/ws/managebooks" />;
      default:
        return <Navigate to="/ws/login" />;
    }
  }

  return children;
};

export default RoleBasedRoutes;
