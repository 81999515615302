import { forwardRef } from 'react';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './draggable-icon.css';

const DraggableIcon = forwardRef((_, ref) => {
  return (
    <div ref={ref} className="draggable-icon">
      <FontAwesomeIcon icon={faEllipsisVertical} />
      <FontAwesomeIcon icon={faEllipsisVertical} />
    </div>
  );
});

export default DraggableIcon;
