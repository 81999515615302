import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomModal } from 'common/components/CustomModal';
import { useAppContext } from '../../../context/AppContext';
import { getRootTests } from '../../../services/testcreate.service';
import { getUserTestFolders } from '../../../services/testfolder.service';
import FoldersList from './FoldersList';
import { toastify } from '../../../../common/components/Toastify';
import { sanitizeTitle } from '../../../utils/test-utils';

function SaveAsModalPopup({ show, handleCloseModal, handleSave, selectedTest }) {
  const intl = useIntl();
  const { dispatchEvent } = useAppContext();

  const [testTitle, setTestTitle] = useState('');
  const [rootFolders, setRootFolders] = useState([]);
  const [isSavingInProgress, setIsSavingInProgress] = useState(false); // State variable to track saving process
  const [rootFolderGuid, setRootFolderGuid] = useState('');

  useEffect(() => {
    document.title = 'Your Tests';
    fetchUserFolders();
  }, []);

  useEffect(() => {
    if (selectedTest && show) {
      setTestTitle(selectedTest.title || '');
    }
  }, [selectedTest, show]);

  /**
   * Fetches the user's test folders and sets them in state.
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   */
  const fetchUserFolders = async () => {
    try {
      const rootFolder = await getRootTests();
      const rootFoldersResponse = await getUserTestFolders(rootFolder.guid);
      setRootFolderGuid(rootFolder.guid);
      setRootFolders(rootFoldersResponse);
      rootFoldersResponse.sort((a, b) => b.sequence - a.sequence);
    } catch (error) {
      console.error('Error getting root folders or folder tests:', error);
      if (error?.response?.status === 409) {
        toastify.showErrorToast(error.response.data.message);
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'error.Failed.root.FoldersOrFolderTests' }));
      }
    }
  };

  /**
   * Handles changes to the title input field, ensuring it only contains allowed characters and is within the maximum length.
   *
   * @param {Event} event The event triggered by the input field change.
   */
  const handleTitleChange = event => {
    let newTitle = sanitizeTitle(event.target.value);

    // Update the state with the new title value
    setTestTitle(newTitle);
  };

  /**
   * Handles the save click event.
   */
  const handleSaveClick = async () => {
    setIsSavingInProgress(true);
    dispatchEvent('SHOW_LOADER');

    try {
      if (testTitle.length > 0) {
        const updatedTest = { ...selectedTest, title: testTitle };
        await handleSave(updatedTest, true);
      } else {
        await handleSave(selectedTest, true);
      }
    } catch (error) {
      toastify.showErrorToast(intl.formatMessage({ id: 'error.Failed.to.save' }));
    } finally {
      setIsSavingInProgress(false);
      dispatchEvent('HIDE_LOADER');
    }
  };

  return (
    <CustomModal show={show}>
      <CustomModal.Header>
        <div style={{ display: 'flex' }}>
          <div style={{ marginBlockStart: '9px' }}>
            <h6>
              <FormattedMessage id="message.saveAs" defaultMessage="Save As" />:
            </h6>
          </div>
          <Form style={{ marginInlineStart: '6px' }}>
            <Form.Control
              type="text"
              name="title"
              placeholder="Enter"
              value={testTitle}
              onChange={handleTitleChange}
              required
            />
          </Form>
        </div>
      </CustomModal.Header>
      <CustomModal.Body>
        <FoldersList rootFolders={rootFolders} fetchUserFolders={fetchUserFolders} rootFolderGuid={rootFolderGuid} />
      </CustomModal.Body>
      <CustomModal.Footer>
        <Button variant="secondary" onClick={handleCloseModal} disabled={isSavingInProgress}>
          <FormattedMessage id="message.cancel" />
        </Button>
        <Button variant="primary" name="saveAs" onClick={handleSaveClick} disabled={isSavingInProgress}>
          <FormattedMessage id="message.save" />
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
}

export default SaveAsModalPopup;
