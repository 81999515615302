import React from 'react';
import { FormattedMessage } from 'react-intl';
import QuestionMapper from '../../questions/QuestionMapper';
import { ESSAY_ANSWER_LINES, QUESTION_TYPES } from '../../../../common/constants/question-constants';
import './PrintTestTreeView.css';

const PrintTestTreeItem = ({ item, index }) => {
  return <QuestionMapper questionNode={item} index={index} hideAnswers shuffleOptions />;
};

const PrintTestTreeView = React.forwardRef(({ savedQuestions, addStudentName, isleftSideMargin }, ref) => {
  console.log('savedQuestions:', savedQuestions);
  return (
    <div className="print-test-tree-view" ref={ref}>
      <div className="p-3">
        {addStudentName && (
          <div className="student-name">
            <span>
              <FormattedMessage id="studentNamePrintTestTreeView" />
            </span>
          </div>
        )}
        {savedQuestions.map((item, index) => {
          return (
            <div
              key={index}
              className={`printViewContainer ${item.quizType === QUESTION_TYPES.FillInBlanks ? 'line-height-2' : ''} ${isleftSideMargin ? 'left-side-margin' : ''}`}
            >
              <PrintTestTreeItem item={item} index={index} />
              {Array.from(
                { length: item.spaceLine + (ESSAY_ANSWER_LINES[item.qtiModel.expectedLines] || 0) },
                (_, spaceIndex) => (
                  <div key={spaceIndex} className="w-100 p-3 mx-2"></div>
                )
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default PrintTestTreeView;
