import { jwtDecode } from 'jwt-decode';
import axios from 'workspace/api/axios';

export const AUTH_ACTIONS = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS', // Action type for successful login
  LOGOUT: 'LOGOUT', // Action type for logout
};

/**
 * Authentication reducer function
 *
 * @param {object} state - Current state of the authentication
 * @param {object} action - Action to be performed on the state
 * @returns {object} New state of the authentication
 */
export const authReducer = (state, action) => {
  switch (action.type) {
    // Handle successful login action
    case AUTH_ACTIONS.LOGIN_SUCCESS: {
      localStorage.setItem('wstoken', action.payload);

      const userData = jwtDecode(action.payload);

      // Update the state with the new token
      return { ...state, token: action.payload, userData };
    }

    // Handle logout action
    case AUTH_ACTIONS.LOGOUT:
      // Clear the authentication token from axios headers and session storage
      delete axios.defaults.headers.common['Authorization'];
      localStorage.removeItem('wstoken');

      // Update the state by removing the token
      return { ...state, token: null, userData: null };

    case AUTH_ACTIONS.SET_ACTIVE_WORKSPACE:
      return {
        ...state,
        userData: {
          ...state.userData,
          workspaceId: action.payload.workspaceId,
          workspaceName: action.payload.workspaceName,
        },
      };

    default:
      console.error(`You passed an action.type: ${action.type} which doesn't exist`);
      return state;
  }
};
