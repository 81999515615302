import QuestionMapper from 'workspace/components/ManageQuestions/questions/QuestionMapper';

export const convertTreeDataToArray = (tree = {}, parent = 0) => {
  // return folders.
  const { folders = [], questions = [] } = tree;
  const data = [];

  const foldersData = constructFolders(folders, parent);
  const questionsData = constructQuestions(questions, parent);

  data.push(...foldersData, ...questionsData);

  for (let folder of folders) {
    if (folder.children && Object.keys(folder.children).length) {
      data.push(...convertTreeDataToArray(folder.children, folder.guid));
    }
  }

  return data;
};

const constructFolders = (folders, parent) => {
  if (!folders) return [];

  return folders.map(folder => {
    const { guid: id, sequence, title, children } = folder;

    return {
      parent,
      id,
      sequence,
      text: title,
      droppable: true,
      isQuestion: false,
      isChildrenLoaded: !!children,
      questionBindings: folder.questionBindings,
    };
  });
};

const constructQuestions = (questions, parent) => {
  if (!questions) return [];

  return questions.map((question, index) => {
    return {
      parent,
      id: question.guid,
      text: <QuestionMapper key={question.guid} questionNode={question} index={index} />,
      isQuestion: true,
      data: question,
      droppable: false,
    };
  });
};

export const isSourceIsParentOfTarget = (treeDataAsArray, sourceId, targetId) => {
  let parent = targetId;

  while (parent) {
    if (parent === sourceId) {
      return true;
    }
    parent = treeDataAsArray.find(node => node.id === parent)?.parent;
  }

  return false;
};

const convertToFlatArray = folders => {
  if (!folders) return [];

  const flatArray = [];

  for (let folder of folders) {
    flatArray.push(folder);

    if (folder.children) {
      flatArray.push(...convertToFlatArray(folder.children.folders));
    }
  }

  return flatArray;
};

export const findSelectedFolder = (folders, id) => {
  // find node in tree stucture
  const flatArray = convertToFlatArray(folders);

  return flatArray.find(folder => folder.guid === id);
};

const getNewSequenceNumber = (folders, sourceFolderIndex) => {
  // Calculate new sequence for the folder
  let newSequence;
  if (folders.length === 1) {
    newSequence = 1;
  } else if (sourceFolderIndex === 0) {
    newSequence = folders[sourceFolderIndex + 1].sequence + 1;
  } else if (sourceFolderIndex === folders.length - 1) {
    newSequence = folders[sourceFolderIndex - 1].sequence - 1;
  } else {
    const maxSequence = folders[sourceFolderIndex - 1]?.sequence;
    const minSequence = folders[sourceFolderIndex + 1]?.sequence;
    newSequence = (minSequence + maxSequence) / 2;
  }

  return newSequence;
};

export const getSourceFolderAndNewSequenceNumber = (state, newTree, dragSource, dropTarget) => {
  // Get parent ID
  const parentId = dropTarget ? dropTarget.id : state.treeData.guid;
  // Get folders in target folder
  const foldersInTree = newTree.filter(item => !item.isQuestion);
  const foldersInTargetFolder = foldersInTree.filter(folder => folder.parent === parentId);

  // Find source folder index and folder
  const sourceFolderIndex = foldersInTargetFolder.findIndex(folder => folder.id === dragSource.id);
  const sourceFolder = foldersInTargetFolder.find(folder => folder.id === dragSource.id);

  // Check for duplicate folder names
  if (foldersInTargetFolder.filter(item => item.text === sourceFolder.text).length > 1) {
    // toastify.showErrorToast(intl.formatMessage({ id: 'error.duplicateFolderDifferentName' }));
    return { error: 'duplicate_folder_name' };
  }

  const sequence = getNewSequenceNumber(foldersInTargetFolder, sourceFolderIndex);

  return { sourceFolder, sequence };
};

/**
 * Calculates the next sequence number for a list of folders.
 *
 * @param {Object[]} [folders=[]] - An array of folder objects, each with a `sequence` property.
 * @returns {number} The next sequence number.
 */
export const getNextSequence = (folders = []) => {
  // Calculate new sequence for the folder
  const maxSequence = Math.max(...folders.map(({ sequence }) => sequence), 0);
  return maxSequence + 1;
};

const getChildNodeIds = (folders = []) => {
  return folders.reduce((acc, folder) => {
    // Add the current folder's GUID
    acc.push(folder.guid);

    // If the folder has children, recursively get their IDs
    if (folder.children) {
      acc.push(...getChildNodeIds(folder.children.folders));
    }

    return acc;
  }, []);
};

export const getNodeIdsToClose = (tree, id) => {
  const currentFolder = findSelectedFolder(tree.children.folders, id);
  const childNodes = currentFolder.children ? getChildNodeIds(currentFolder.children.folders) : [];

  return [id, ...childNodes];
};
