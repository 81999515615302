import axios from './axios';
import { extractErrorDetails } from 'common/utils';

/**
 * Adds a new book to the server.
 *
 * @param {Object} bookData - The book data to be added.
 * @returns {Promise<Object>} - A promise that resolves to the added book's data.
 * @throws {Error} - An error with a user-friendly error message if the addition fails.
 */
export async function addBook(bookData) {
  try {
    const response = await axios.post('ws/addBook', bookData);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function updateBook(bookId, bookData) {
  try {
    const response = await axios.put(`/ws/books/${bookId}`, bookData);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function getBooks(showCard) {
  try {
    const url = `ws/books${showCard ? '?showAsCard=true' : ''}`;
    const response = await axios.get(url);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function deleteBook(bookId) {
  try {
    const response = await axios.delete(`/ws/books/${bookId}`);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function addBookList(selectedBooksArray) {
  try {
    const response = await axios.post('ws/books/cards', selectedBooksArray);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function getAllCards() {
  try {
    const response = await axios.get('ws/books/cards');
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function publishBook(bookId) {
  try {
    const response = await axios.post(`ws/books/${bookId}/publish`);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function republishBook(bookId) {
  try {
    const response = await axios.post(`ws/books/${bookId}/republish`);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function unpublishBook(bookId) {
  try {
    const response = await axios.post(`ws/books/${bookId}/unpublish`);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function getBookHistory(bookId) {
  try {
    const response = await axios.get(`/ws/books/${bookId}/history`);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}
