import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomModal } from 'common/components/CustomModal';
import PrintTestTreeView from './PrintTestTreeView';
import { ANSWER_AREAS } from '../../../constants/print-constants';
import { deepClone } from 'common/utils';

const PrintPreview = React.forwardRef(({ questions, options = {}, onClose }, printableContentRef) => {
  const { addStudentName, answerArea, lineBreaks } = options;
  const updatedQuestions = deepClone(questions);

  if (answerArea === ANSWER_AREAS.BETWEEN_QUESTIONS) {
    for (let key in lineBreaks) {
      updatedQuestions[key - 1].spaceLine = lineBreaks[key];
    }
  } else if (answerArea === ANSWER_AREAS.BLANK_LAST_PAGE) {
    updatedQuestions[updatedQuestions.length - 1].spaceLine = 34;
  }

  return (
    <div className="print-preview" id="printPreviewArea">
      <CustomModal.Header className="print-preview-header" backdrop="static" onClose={onClose}>
        <h3>
          <FormattedMessage id="print.preview" />
        </h3>
      </CustomModal.Header>
      <CustomModal.Body className="questions-list flex-grow-1">
        <div id="print-test">
          {updatedQuestions && (
            <div className="print-tree-view-container">
              <PrintTestTreeView
                ref={printableContentRef}
                savedQuestions={updatedQuestions}
                addStudentName={addStudentName}
                isleftSideMargin={answerArea === ANSWER_AREAS.LEFT_SIDE_OF_THE_PAGE}
              />
            </div>
          )}
        </div>
      </CustomModal.Body>
    </div>
  );
});

export default PrintPreview;
