import axios from './axios';
import { extractErrorDetails } from 'common/utils';

export const uploadFile = async data => {
  try {
    const response = await axios.post('ws/images', data, { headers: { 'Content-Type': 'multipart/form-data' } });
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};
