import axios from './axios';
import { extractErrorDetails } from 'common/utils';

export async function addWorkspace(workspaceData) {
  try {
    const response = await axios.post('ws/workspace', workspaceData);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function getAllWorksapce() {
  try {
    const response = await axios.get('ws/workspace');
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function updateWorkspace(workspaceId, workspaceData) {
  try {
    const response = await axios.put(`/ws/workspace/${workspaceId}`, workspaceData);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}
