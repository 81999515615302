import { Button } from 'react-bootstrap';
import { CustomModal } from '../CustomModal';

const ConfirmModal = ({ config, onConfirm, onCancel }) => {
  const { title, message, cancelText, confirmText } = config;
  return (
    <CustomModal show={true} size="sm" centered={false}>
      {title && <CustomModal.Header>{title}</CustomModal.Header>}
      <CustomModal.Body>{message}</CustomModal.Body>
      <CustomModal.Footer>
        <Button variant="secondary" size="sm" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button variant="primary" size="sm" onClick={onConfirm}>
          {confirmText}
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
};

export default ConfirmModal;
