import ReadOnlyActionButtons from '../ReadOnlyActionButtons';
import { sanitizedData } from 'common/utils';
import { getPrintModeFbCaption } from './fib-utils';
import '../questions.css';

const FillInBlanksReadOnly = props => {
  const { questionNode, index, showActionButtons = false } = props;

  const { Caption, Blanks } = questionNode.qtiModel;

  return (
    <div className="d-flex align-items-center question-container">
      <div className="flex-grow-1 d-flex align-items-center flex-wrap">
        <div className="d-flex align-items-start gap-2 w-100">
          {index !== undefined && <div>{index + 1})</div>}
          <div
            className="question-content flex-grow-1"
            dangerouslySetInnerHTML={sanitizedData(getPrintModeFbCaption(Caption, Blanks))}
          ></div>
        </div>
      </div>

      {showActionButtons && <ReadOnlyActionButtons {...props} />}
    </div>
  );
};

export default FillInBlanksReadOnly;
