import { toast } from 'react-toastify';

const options = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 3000,
  theme: 'colored',
  className: 'toast-right-to-left',
};

export const toastify = {
  showSuccessToast: message => {
    toast(message, { ...options, type: 'success' });
  },
  showWarningToast: message => {
    toast(message, { ...options, type: 'warning' });
  },
  showErrorToast: message => {
    toast(message, { ...options, type: 'error' });
  },
  showInfoToast: message => {
    toast(message, { ...options, type: 'info' });
  },
};
