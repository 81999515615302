import React, { useEffect, useState } from 'react';
import { Form, Card, Button, Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useConfirmDialog } from 'common/components/ConfirmationDialog';
import { toastify } from 'common/components/Toastify';
import { deleteUserFromWorkspace } from 'workspace/api/users.service';
import { USER_ROLE_INFO } from 'workspace/constants';
import SearchBox from 'common/components/SearchBox/SearchBox';
import { useAuth } from 'workspace/providers/auth/AuthContext';
import 'react-toastify/dist/ReactToastify.css';

const UsersList = ({ users, handleShowModalPopup, handleEditUser, fetchUsers, loading }) => {
  const { showConfirmDialog } = useConfirmDialog();
  const intl = useIntl();
  const { userData } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [displayDeletedUsers, setDisplayDeletedUsers] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleDeleteUserClick = user => {
    const userName = `${user.firstName} ${user.lastName}`;
    showConfirmDialog({
      title: intl.formatMessage({ id: 'ws.modal.delete.confirmation.title' }),
      message: (
        <span>
          {intl.formatMessage(
            { id: 'ws.modal.delete.user.confirmation.message' },
            {
              userName: <strong>{userName}</strong>,
            }
          )}
        </span>
      ),
      confirmText: intl.formatMessage({ id: 'message.delete' }),
      cancelText: intl.formatMessage({ id: 'message.cancel' }),
      onConfirm: () => {
        handleDeleteUserConfirm(user);
      },
    });
  };

  const handleDeleteUserConfirm = async selectedUser => {
    try {
      await deleteUserFromWorkspace(selectedUser.id);

      toastify.showSuccessToast(
        intl.formatMessage({ id: 'success.userdelete', defaultMessage: 'User deleted successfully' })
      );
      fetchUsers();
    } catch (error) {
      toastify.showErrorToast(intl.formatMessage({ id: 'error.userdelete', defaultMessage: 'Error deleting user' }));
    }
  };

  const handleEdit = user => {
    handleEditUser(user);
    handleShowModalPopup();
  };

  const handleSearchChange = value => {
    setSearchTerm(value);
  };

  const filteredUsers = users.filter(user => {
    const fullName = `${user['firstName'] || ''} ${user['lastName'] || ''}`.toLowerCase();
    const userName = (user['userName'] || '').toLowerCase();
    const email = (user['email'] || '').toLowerCase();

    return (
      (displayDeletedUsers || !user.deleted) &&
      (fullName.includes(searchTerm.toLowerCase()) ||
        userName.includes(searchTerm.toLowerCase()) ||
        email.includes(searchTerm.toLowerCase()))
    );
  });

  const handleDisplayDeletedUsers = event => {
    setDisplayDeletedUsers(event.target.checked);
  };

  return (
    <>
      <Row>
        <Col xs={12} className="users-list-actions-container">
          <div className="users-list-actions-left">
            <SearchBox searchText={searchTerm} placeholder="Search Users" onSearch={handleSearchChange} />
          </div>
          <div className="users-list-actions-right">
            <Form.Check
              label={intl.formatMessage({ id: 'message.displayDeletedUsers' })}
              checked={displayDeletedUsers}
              onChange={handleDisplayDeletedUsers}
            />
            <Button variant="primary" size="sm" onClick={handleShowModalPopup}>
              <FormattedMessage id="ws.adduser" />
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="users-list-row">
        {!loading && users.length === 0 && (
          <div className="no-matching-users d-flex justify-content-center align-items-center" tabIndex="0">
            <FormattedMessage id="ws.nousersfound" defaultMessage="No users found." />
          </div>
        )}
        {filteredUsers.map(user => (
          <Col key={user.id} className="user-details-card">
            <Card className={`custom-card-user ${user.deleted ? 'disabled-card' : ''}`}>
              <Card.Body className="user-card-body-top">
                <div className="user-role-details">
                  <div>
                    <i className={`fa-solid fa-3x user-icon ${USER_ROLE_INFO[user.role].iconClass}`}></i>
                  </div>
                  <div className="user-name" title={`${user.firstName} ${user.lastName}`}>
                    {user.firstName} {user.lastName}
                  </div>
                  <div className="user-role-display">{USER_ROLE_INFO[user.role].title}</div>
                </div>
              </Card.Body>
              <Card.Body className="user-card-body-bottom">
                <div className="button-group">
                  <Button variant="primary" size="sm" className="user-card-buttons" onClick={() => handleEdit(user)}>
                    <FormattedMessage id="message.edit" />
                  </Button>
                  <Button
                    variant="primary"
                    size="sm"
                    className="user-card-buttons"
                    onClick={() => handleDeleteUserClick(user)}
                  >
                    <FormattedMessage id="message.delete" />
                  </Button>
                </div>
                <Card.Text className="user-card-text" title={`${user.email}`}>
                  {user.email}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
        {filteredUsers.length === 0 && users.length != 0 && (
          <div className="no-matching-users d-flex justify-content-center align-items-center" tabIndex="0">
            <FormattedMessage id="noMatchingUsersMessage" defaultMessage="No matching users found" />
          </div>
        )}
      </Row>
    </>
  );
};

export default UsersList;
