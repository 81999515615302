import { useEffect } from 'react';
import ManageWorkspace from 'workspace/components/ManageWorkspace/ManageWorkspace';

const ManageWorkspacePage = () => {
  useEffect(() => {
    document.title = 'Manage Workspace';
  }, []);

  return (
    <>
      <ManageWorkspace />
    </>
  );
};
export default ManageWorkspacePage;
