import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Editor, EditorProvider, Toolbar } from 'lib/rte';
import EditViewActionButtons from '../EditViewActionButtons';
import { toastify } from 'common/components/Toastify';
import FormatAndAddExtendedMetadataSection from '../FormatAndAddExtendedMetadataSection';
import useFormatAndExtendedMetadata from '../hooks/useFormatAndExtendedMetadata';

const MAX_OPTIONS_COUNT = process.env.REACT_APP_ANSWER_OPTIONS_COUNT;

const MultipleChoiceEditView = props => {
  const { questionNode, userMetadata } = props;
  const { Caption, Options, CorrectAnswer } = questionNode.qtiModel;
  const intl = useIntl();

  const [questionData, setQuestionData] = useState({
    Caption: Caption ?? '',
    Options: Options ?? ['', '', '', ''],
    CorrectAnswer: CorrectAnswer ?? -1,
  });

  const [formatAndExtendedMetadata, setFormatAndExtendedMetadata] = useFormatAndExtendedMetadata(questionNode);

  const handleChange = e => {
    const { name, value } = e.target || e;

    setQuestionData(prev => ({ ...prev, [name]: value }));
  };

  const handleOptionsChange = e => {
    const { name, value } = e.target || e;
    //copying data to temp variable so that we do not directly mutate original state
    const tempOptions = [...questionData.Options];
    //findIndex to find location of item we need to update
    tempOptions[name] = value;
    setQuestionData({ ...questionData, Options: tempOptions });
  };

  const handleAddOption = () => {
    if (questionData.Options.length < MAX_OPTIONS_COUNT) {
      setQuestionData(prev => ({
        ...prev,
        Options: [...prev.Options, ''],
      }));
    } else {
      const msg = intl.formatMessage({ id: 'warning.moreoptions' }, { count: MAX_OPTIONS_COUNT });
      toastify.showWarningToast(msg);
    }
  };

  const handleRemoveOption = index => {
    if (questionData.Options.length > 2) {
      let newCorrectAnswer = questionData.CorrectAnswer;
      if (index === questionData.CorrectAnswer) {
        newCorrectAnswer = -1;
      } else if (index < questionData.CorrectAnswer) {
        newCorrectAnswer = questionData.CorrectAnswer - 1;
      }

      setQuestionData(prev => ({
        ...prev,
        Options: prev.Options.filter((opt, i) => i !== index),
        CorrectAnswer: newCorrectAnswer,
      }));
    }
  };

  return (
    <div className="m-2 border rounded p-3 bg-light">
      <EditorProvider>
        <Toolbar />
        <Form className="editmode paddingright10">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="mb-1" tabIndex="0">
              <b>{intl.formatMessage({ id: 'label.multipleChoice.title' })}</b>
            </Form.Label>
            <Editor
              name="Caption"
              value={questionData.Caption}
              className="mb-2"
              placeholder={intl.formatMessage({ id: 'placeholder.multipleChoice.caption' })}
              onChange={value => handleChange({ name: 'Caption', value })}
            />
            <Form.Group className="mb-1 mt-3 d-flex flex-wrap">
              {questionData?.Options?.length > 0 &&
                questionData?.Options.map((optItem, index) => {
                  return (
                    <Form.Group key={index} className="mc-flex-row mb-2">
                      <div className="mc-col mc-col-1" tabIndex="0">
                        <Form.Check
                          type="radio"
                          checked={index === questionData.CorrectAnswer}
                          value={index}
                          name="CorrectAnswer"
                          className="item-1"
                          onChange={e => setQuestionData(prev => ({ ...prev, CorrectAnswer: index }))}
                        />
                      </div>
                      <div className="mc-col mc-col-2">
                        <Editor
                          key={`editor-${index}-${questionData.Options.length}`}
                          name={index}
                          value={optItem}
                          className="item-2"
                          placeholder={intl.formatMessage({ id: 'placeholder.multipleChoice.option' })}
                          onChange={value => handleOptionsChange({ name: index, value })}
                        />
                      </div>
                      <Button
                        className="opt-close-button highlight-icon-button"
                        title={intl.formatMessage({
                          id: 'message.remove',
                          defaultMessage: 'Delete Option',
                        })}
                        variant="link"
                        onClick={() => handleRemoveOption(index)}
                        disabled={index == questionData.CorrectAnswer || questionData.Options.length <= 2}
                      >
                        <i className="bi bi-x-lg"></i>
                      </Button>
                    </Form.Group>
                  );
                })}
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button onClick={handleAddOption}>
                <FormattedMessage id="addOptionsforMultiplechoice" />
              </Button>
            </div>
          </Form.Group>

          <FormatAndAddExtendedMetadataSection
            data={formatAndExtendedMetadata}
            setData={setFormatAndExtendedMetadata}
            userMetadata={userMetadata}
          />
        </Form>

        <EditViewActionButtons
          {...props}
          questionData={questionData}
          formatAndExtendedMetadata={formatAndExtendedMetadata}
        />
      </EditorProvider>
    </div>
  );
};

export default MultipleChoiceEditView;
