import React, { useEffect, useState } from 'react';
import { ListGroup, Badge, Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { CustomModal } from 'common/components/CustomModal';
import { BOOK_STATUS } from 'workspace/constants';
import { getBookHistory } from 'workspace/api/books.service';
import { toastify } from 'common/components/Toastify';
import { useLoader } from 'common/providers/LoaderProvider';

const bookStatusMap = {
  [BOOK_STATUS.NEW]: { badge: 'info', id: 'ws.status.new', defaultMessage: 'New' },
  [BOOK_STATUS.PUBLISHED]: { badge: 'success', id: 'ws.published', defaultMessage: 'Published' },
  [BOOK_STATUS.REPUBLISHED]: { badge: 'warning', id: 'ws.republished', defaultMessage: 'Republished' },
  [BOOK_STATUS.UNPUBLISHED]: { badge: 'secondary', id: 'ws.unpublished', defaultMessage: 'Unpublished' },
};

const getStatusBadge = status => {
  const { badge = 'light', id = 'ws.status.unknown', defaultMessage = 'Unknown Status' } = bookStatusMap[status] || {};

  return (
    <Badge bg={badge}>
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
    </Badge>
  );
};

const BookDetailsModal = ({ book, handleClose }) => {
  const [history, setHistory] = useState([]);
  const { showLoader, hideLoader } = useLoader();

  useEffect(() => {
    if (book) {
      const fetchHistory = async () => {
        try {
          const historyData = await getBookHistory(book.guid);
          setHistory(historyData);
          showLoader();
        } catch (error) {
          toastify.showErrorToast(error?.message);
        } finally {
          hideLoader();
        }
      };

      fetchHistory();
    }
  }, [book]);

  if (!book) return null;

  return (
    <CustomModal show={true} size="lg" className="book-details-modal">
      <CustomModal.Header onClose={handleClose}>
        <FormattedMessage id="ws.bookdetails" defaultMessage="Book Details" />
      </CustomModal.Header>
      <CustomModal.Body>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.booktitle" defaultMessage="Book Title" />:
            </strong>
            &nbsp;
            {book.title}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.bookdescription" defaultMessage="Description" />:
            </strong>
            &nbsp;
            {book.description}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.author" defaultMessage="Author" />:
            </strong>
            &nbsp;
            {book.authors.map((author, index) => (
              <span key={index}>
                {author}
                {index < book.authors.length - 1 && ', '}
              </span>
            ))}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.createdby" defaultMessage="Created By" />:
            </strong>
            &nbsp;
            {book.creatorName}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.createddate" defaultMessage="Created Date" />:
            </strong>
            &nbsp;
            {new Date(book.created).toLocaleDateString()}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.discipline" defaultMessage="Discipline" />:
            </strong>
            &nbsp;
            {book.discipline}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.publisher" defaultMessage="Publisher" />:
            </strong>
            &nbsp;
            {book.publisher}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.status" defaultMessage="Status" />:
            </strong>
            &nbsp;
            {getStatusBadge(book.status)}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.bookhistory" defaultMessage="Book History" />:
            </strong>
          </ListGroup.Item>
        </ListGroup>

        <div className="book-history-table-container">
          <Table className="book-history-table" striped bordered hover size="sm">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="ws.publishedBookID" defaultMessage="Published Book Name" />
                </th>
                <th>
                  <FormattedMessage id="ws.publishedDate" defaultMessage="Published Date" />
                </th>
                <th>
                  <FormattedMessage id="ws.publishedBy" defaultMessage="Published By" />
                </th>
                <th className="text-center">
                  <FormattedMessage id="ws.status" defaultMessage="Status" />
                </th>
              </tr>
            </thead>
            <tbody>
              {history?.length ? (
                history.map((record, index) => (
                  <tr key={index}>
                    <td className="mw-200px text-truncate">{record.bookName}</td>
                    <td>{new Date(record.publishedDate).toLocaleDateString('en-GB')}</td>
                    <td className="mw-100px text-truncate">{record.publishedBy}</td>
                    <td className="text-center">
                      {record.disabled ? (
                        <i className="bi bi-x status-x fs-3" title="InActive"></i>
                      ) : (
                        <i className="bi bi-check status-check fs-3" title="Active"></i>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    <FormattedMessage id="ws.noHistory" defaultMessage="No history available" />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </CustomModal.Body>
    </CustomModal>
  );
};

export default BookDetailsModal;
