import { Outlet, useLocation } from 'react-router-dom';
import ProfileModal from '../ProfileModal/ProfileModal';
import Header from 'common/components/layout/Header';
import WSTabs from 'workspace/components/layout/WSTabs';
import { useAuth } from 'workspace/providers/auth/AuthContext';
import { uploadFile } from 'workspace/api/file.service';
import { EditorRootProvider } from 'lib/rte/provider/EditorRootProvider';
import { ROLES } from 'workspace/constants';
import './layout.css';

const WSLayout = () => {
  const { pathname } = useLocation();
  const { userData } = useAuth();

  const hideTabs = userData?.role === ROLES.SUPER_ADMIN && pathname === '/ws/manageworkspace';

  return (
    <>
      <div className="ws-root">
        <Header hideHelp workspaceName={userData.workspaceName}>
          <ProfileModal />
        </Header>
        {!hideTabs && <WSTabs />}

        <div className="container-resource flex-grow-1 overflow-auto">
          <EditorRootProvider imageUploadAPIService={uploadFile}>
            <Outlet />
          </EditorRootProvider>
        </div>
      </div>
    </>
  );
};
export default WSLayout;
