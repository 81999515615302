import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomModal } from 'common/components/CustomModal';
import { useAppContext } from '../../../context/AppContext';
import { toastify } from '../../../../common/components/Toastify';
import { exportTest, getPrintsettings, savePrintsettings } from '../../../services/testcreate.service';
import { downloadFile } from '../../../utils/common';
import {
  ANSWER_KEYS,
  answerAreas,
  answerKeys,
  exportFileFormats,
  FILE_FORMATS,
  margins,
  pageNumbers,
} from '../../../constants/export-constants';
import { generateFileName } from './export-utils';
import './ExportModalPopup.css';

function ExportModalPopup({ show, handleCloseModal, selectedTest }) {
  const intl = useIntl();
  const { dispatchEvent } = useAppContext();
  const [selectedFormat, setSelectedFormat] = useState(exportFileFormats[0]);
  const [selectedAnswerArea, setSelectedAnswerArea] = useState(answerAreas[0]);
  const [selectedAnswerKey, setSelectedAnswerKey] = useState(answerKeys[0]);
  const [selectedMargin, setSelectedMargin] = useState(margins[1]);
  const [selectedPageNumber, setSelectedPageNumber] = useState(pageNumbers[0]);
  const [isSaveSettingsAsDefault, setIsSaveSettingsAsDefault] = useState(false);
  const [showMSWordSetting, setShowMSWordSetting] = useState(true);
  // const [isIncludeRandomizedTest, setIsIncludeRandomizedTest] = useState(false);
  const [isIncludeStudentName, setIsIncludeStudentName] = useState(false);

  useEffect(() => {
    if (show) {
      fetchExportSettings();
    }
  }, [show]);

  /**
   * Fetches the export settings from the server and updates the component state with the fetched data.
   */
  const fetchExportSettings = async () => {
    try {
      dispatchEvent('SHOW_LOADER');
      const settings = await getPrintsettings();
      const format = exportFileFormats.find(f => f.value === settings.exportFileFormat) || exportFileFormats[0];
      const answerArea = answerAreas.find(a => a.value === settings.includeAreaForStudentResponse) || answerAreas[0];
      const answerKey = answerKeys.find(k => k.value === settings.includeAnswerKeyIn) || answerKeys[0];
      const pageNumber = pageNumbers.find(p => p.value === settings.pageNumberDisplay) || pageNumbers[0];
      const margin = margins.find(m => m.value === settings.topMargin) || margins[1];

      setSelectedFormat(format);
      setShowMSWordSetting(isMSWordOrPDFSelected(format.value));
      setSelectedAnswerArea(answerArea);
      setSelectedAnswerKey(answerKey);
      setSelectedPageNumber(pageNumber);
      setSelectedMargin(margin);
      setIsIncludeStudentName(settings.includeStudentName);
      // setIsIncludeRandomizedTest(settings.includeRandomizedTests);
    } catch (error) {
      console.error('Failed to fetch export settings:', error);
      toastify.showErrorToast(error);
    } finally {
      dispatchEvent('HIDE_LOADER');
    }
  };

  /**
   * Checks if the given file format is either MS Word (.doc) or PDF.
   *
   * @param {string} formatValue The file format to check.
   * @returns {boolean} True if the format is MS Word or PDF, false otherwise.
   */
  const isMSWordOrPDFSelected = formatValue => {
    return ['doc', 'pdf'].includes(formatValue);
  };

  /**
   * Handles a change in the format selection.
   *
   * @param {object} format The newly selected format.
   */
  const handleFormatChange = format => {
    setSelectedFormat(format);
    setShowMSWordSetting(isMSWordOrPDFSelected(format.value));
  };

  /**
   * Saves the print settings to the server.
   * @return {Promise} A promise that resolves when the settings have been saved.
   */
  const saveSettings = async () => {
    const payload = {
      multipleVersions: false, // isIncludeRandomizedTest
      numberOfVersions: 0, // assuming it's always 1
      scrambleOrder: 'Scramble question order',
      includeAreaForStudentResponse: selectedAnswerArea.value,
      includeAnswerKeyIn: selectedAnswerKey.value,
      includeAnwserFeedback: true,
      includeQuestionHints: true,
      topMargin: selectedMargin.value,
      bottomMargin: selectedMargin.value,
      leftMargin: selectedMargin.value,
      rightMargin: selectedMargin.value,
      headerSpace: '1.2',
      footerSpace: '1.2',
      font: 'Helvetica, Arial',
      fontSize: '12', // assuming this is a static value
      exportFileFormat: selectedFormat.value,
      includeRandomizedTests: false,
      includeStudentName: isIncludeStudentName,
      pageNumberDisplay: selectedPageNumber.value,
    };

    return savePrintsettings(payload);
  };

  /**
   * Saves the settings if the 'Save as default' option is selected.
   *
   * @async
   * @returns {Promise} A promise that resolves when the settings are saved.
   */
  const saveSettingsIfSaveAsDefaultSelected = async () => {
    if (isSaveSettingsAsDefault) {
      try {
        await saveSettings();
        toastify.showSuccessToast(intl.formatMessage({ id: 'success.exportTestSettingsSave' }));
      } catch (error) {
        const errorMessage = intl.formatMessage({ id: 'error.exportTestSettingsSave' });
        toastify.showErrorToast(`${errorMessage}: ${error.message}`);
      }
    }
  };

  /**
   * Prepares and returns an object containing options for exporting a test.
   */
  const prepareOptions = () => {
    return {
      answerKey: selectedAnswerKey.value,
      answerArea: selectedAnswerArea.value,
      includeRandomizedTests: false,
      includeStudentName: isIncludeStudentName,
      saveSettings: false,
      margin: selectedMargin.value,
      pageNumberDisplay: selectedPageNumber.value,
    };
  };

  /**
   * Handles the export of a test
   */
  const handleExport = async () => {
    dispatchEvent('SHOW_LOADER');

    // Save default settings if the "Save as default" option is selected
    await saveSettingsIfSaveAsDefaultSelected();

    // Prepare the export options
    const options = prepareOptions();

    try {
      // Create an array to store the download API calls
      const downloadAPICalls = [];

      // If the answer key should be exported to a separate file, add a separate API call
      if (
        [FILE_FORMATS.DOC, FILE_FORMATS.PDF].includes(selectedFormat.value) &&
        options.answerKey === ANSWER_KEYS.SEPARATEFILE
      ) {
        downloadAPICalls.push(
          exportTest(selectedTest.testId, selectedFormat.value, { ...options, answerKey: ANSWER_KEYS.NONE })
        );
      }

      // Add the main API call to export the test
      downloadAPICalls.push(exportTest(selectedTest.testId, selectedFormat.value, options));

      // Wait for all API calls to complete and retrieve the blobs
      const blobs = await Promise.all(downloadAPICalls);

      // Process each blob and generate a file name
      for (let i = 0; i < blobs.length; i++) {
        const blob = blobs[i];
        const fileName = generateFileName(selectedTest.title, selectedFormat, i === 1);

        // Download the file
        downloadFile(blob, fileName);
        // Wait for a second before downloading another file
        await new Promise(r => setTimeout(r, 1000));
      }

      toastify.showSuccessToast(intl.formatMessage({ id: 'success.documentExport' }));

      handleCloseModal();
    } catch (error) {
      toastify.showErrorToast(error);
    } finally {
      dispatchEvent('HIDE_LOADER');
    }
  };

  return (
    <CustomModal className="export-modal-popup" show={show} centered={false}>
      <CustomModal.Header onClose={handleCloseModal}>
        <FormattedMessage id="exportTestsModalpopupTitle" />
      </CustomModal.Header>
      <CustomModal.Body>
        <Form>
          <Form.Group as={Row}>
            <Col sm="6" tabIndex="0">
              <Form.Label column sm="6" style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                <FormattedMessage id="exportFormatLabel" />
              </Form.Label>

              {exportFileFormats.map((format, index) => (
                <Form.Check
                  type="radio"
                  label={format.text}
                  name="exportFormat"
                  id={`exportFormat-${index}`}
                  key={`exportFormat-${index}`}
                  checked={selectedFormat.value === format.value}
                  onChange={() => handleFormatChange(format)}
                />
              ))}
            </Col>
            {showMSWordSetting && (
              <Col sm="6" tabIndex="0">
                <Form.Label column sm="6" style={{ fontWeight: 'bold' }}>
                  <FormattedMessage id="answerAreaLabel" />
                </Form.Label>
                {answerAreas.map((area, index) => (
                  <Form.Check
                    type="radio"
                    label={area.text}
                    name="answerArea"
                    id={`answerArea-${index}`}
                    key={`answerArea-${index}`}
                    checked={selectedAnswerArea.value === area.value}
                    onChange={() => setSelectedAnswerArea(area)}
                    disabled={area.isDisabled}
                  />
                ))}
              </Col>
            )}
          </Form.Group>

          {showMSWordSetting && (
            <>
              <hr />
              {/* Margins Section */}
              <Form.Group as={Row}>
                <Col sm="6" tabIndex="0">
                  <Form.Label column sm="6" style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="marginsLabel" />
                  </Form.Label>

                  {margins.map((margin, index) => (
                    <Form.Check
                      type="radio"
                      label={margin.text}
                      name="margins"
                      id={`margin-${index}`}
                      key={`margin-${index}`}
                      checked={selectedMargin.value === margin.value}
                      onChange={() => setSelectedMargin(margin)}
                    />
                  ))}
                </Col>
                <Col sm="6" tabIndex="0">
                  <Form.Label column sm="6" style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="answerKeyLabel" />
                  </Form.Label>

                  {answerKeys.map((key, index) => (
                    <Form.Check
                      type="radio"
                      label={key.text}
                      name="answerKey"
                      id={`answerKey-${index}`}
                      key={`answerKey-${index}`}
                      checked={selectedAnswerKey.value === key.value}
                      onChange={() => setSelectedAnswerKey(key)}
                    />
                  ))}
                </Col>
              </Form.Group>
              <hr />
              {/*  Answer Area Section */}
              <Form.Group as={Row}>
                <Col sm="6" tabIndex="0">
                  <Form.Label column sm="6" style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="pageNumberLabel" />
                  </Form.Label>

                  {pageNumbers.map((number, index) => (
                    <Form.Check
                      type="radio"
                      label={number.text}
                      name="pageNumber"
                      id={`pageNumber-${index}`}
                      key={`pageNumber-${index}`}
                      checked={selectedPageNumber.value === number.value}
                      onChange={() => setSelectedPageNumber(number)}
                    />
                  ))}
                </Col>
                <Col sm="6" tabIndex="0">
                  <Form.Label column sm="6" style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                    <FormattedMessage id="labelsAndVersionsLabel" />
                  </Form.Label>

                  <Form.Check
                    type="checkbox"
                    label={intl.formatMessage({ id: 'exportTest.addStudentNameLabel&Space' })}
                    checked={isIncludeStudentName}
                    onChange={e => setIsIncludeStudentName(e.target.checked)}
                  />

                  {/* <Form.Check
                      type="checkbox"
                      label="Include all test versions"
                      checked={isIncludeRandomizedTest}
                      onChange={e => setIsIncludeRandomizedTest(e.target.checked)}
                    /> */}
                </Col>
              </Form.Group>
            </>
          )}
        </Form>
      </CustomModal.Body>
      <CustomModal.Footer>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={6} tabIndex="0">
              <Form.Check
                style={{ paddingLeft: '10px' }}
                type="checkbox"
                label="Save settings as default"
                checked={isSaveSettingsAsDefault}
                onChange={e => setIsSaveSettingsAsDefault(e.target.checked)}
              />
            </Col>
            <Col xs={6} className="action-buttons-container" style={{ paddingRight: '0px' }}>
              <Button variant="secondary" onClick={handleCloseModal} style={{ marginLeft: '10px' }}>
                <FormattedMessage id="message.cancel" />
              </Button>
              <Button variant="primary" onClick={handleExport}>
                <FormattedMessage id="exportButtonModalpopupExportTextText" />
              </Button>
            </Col>
          </Row>
        </Container>
      </CustomModal.Footer>
    </CustomModal>
  );
}

export default ExportModalPopup;
