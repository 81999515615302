import { FormattedMessage } from 'react-intl';
import QuestionMapper from './questions/QuestionMapper';
import { useManageQuestionsContext } from 'workspace/providers/manage-questions/ManageQuestionsProvider';

const QuestionsListContainer = () => {
  const { activeQuestions } = useManageQuestionsContext();

  return activeQuestions.length ? (
    <div>
      {activeQuestions.map((question, index) => (
        <div key={question.itemId} className="p-2">
          <QuestionMapper questionNode={question} index={index} showActionButtons />
        </div>
      ))}
    </div>
  ) : (
    <div className="manage-questions-instructions-container">
      <div className="flex-box" tabIndex="0">
        <i className="far fa-lightbulb light-orange" aria-label="bulb icon" tabIndex="0"></i>

        <div className="question-instructions-container">
          <p className="bold-text">
            <FormattedMessage id="ws.instructions.questionCreationFromButton" />
          </p>
          <p className="bold-text">
            <FormattedMessage id="ws.instructions.folderSelection" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuestionsListContainer;
